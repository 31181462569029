<template>
  <v-card class="pa-3" rounded="2" color="primary lighten-5" elevation="4">
    <v-layout class="" style="position: absolute; z-index:1; transform: rotate(-4deg)" 
        align-start justify-start>
      <v-card class="transparent py-1 px-2" rounded="5" flat>
        <v-img :style="imageStyle" class="" src='../images/GroupBack.png' ></v-img>
        <span class="text-h4 text-sm-h5 text-md-h4 accent--text pa-4" id="haeusle">{{group.message.groupname}}</span>
      </v-card>
    </v-layout>
    <v-row>
      <v-col cols="12" sm="6">
        <v-img v-if="group.pictures[0]" style="filter: grayscale(0.5) contrast(1.5)" :src="group.pictures[0]" class="mt-1"></v-img>
        <v-card v-else height="40" color="transparent" flat></v-card>
        <br>
        <br>
        <div v-for="part in contentStringArray" :key="part">
          <span class="text-h6 font-weight-regular">{{part}}</span>
          <br>
        </div>
      </v-col>
      <v-col class="mt-3" cols="12" sm="6">
        <v-card color="red lighten-3" class="mt-n2" :style="infoBackStyle" :height="backStyleSize.height" :width="backStyleSize.width"></v-card>
        <div ref="datacard">
          <v-card flat color="transparent" class="pa-3">
            <div>
              <h4>{{group.message.groupname}}</h4>
              <br>
              <v-row  >
                <v-col cols="1" class="py-2" align-self="center">
                  <v-icon >mdi-calendar</v-icon>
                </v-col>
                <v-col auto class="py-2" align-self="center">
                  <h4 >{{group.message.day}}</h4>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="1" class="py-2" align-self="center">
                  <v-icon >mdi-repeat</v-icon>
                </v-col>
                <v-col auto class="py-1" align-self="center">
                  <h4 >{{group.message.repeat}}</h4>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="1" class="py-2" align-self="center">
                  <v-icon >mdi-clock</v-icon>
                </v-col>
                <v-col auto class="py-2" align-self="center">
                  <h4 >{{group.message.clock}}</h4>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="1" class="pt-1 pb-3" align-self="center">
                  <v-icon >mdi-home-group</v-icon>
                </v-col>
                <v-col auto class="pt-1 pb-3" align-self="center">
                  <h4 >{{group.message.where}}</h4>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="1" class="pt-1 pb-3" align-self="center">
                  <v-icon >mdi-account-multiple</v-icon>
                </v-col>
                <v-col auto class="pt-1 pb-3" align-self="center">
                  <h4 >{{group.message.who}}</h4>
                </v-col>
              </v-row>
              <!-- PS -->
              <div v-for="part in psStringArray" :key="part" class="my-1">
                <span class="font-weight-regular">{{part}}</span>
                <br>
              </div>
                <br>
              <!-- LINK -->
              <div v-if="group.message.link && group.message.link!=''">
                <a v-if="group.message.link.substring(0,1) != '/'" :href="group.message.link" target="_blank">
                  <h3 v-if="group.message.linktext" class="mb-0 ml-3 haeusle accent--text">
                    <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
                    <span class="ml-2">{{group.message.linktext}}</span>
                  </h3>
                  <h3 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
                    <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
                    <span class="ml-2">{{group.message.link}}</span>
                  </h3>
                </a>
                <router-link v-if="group.message.link.substring(0,1) == '/'" :to="group.message.link">
                  <h3 v-if="group.message.linktext" class="mb-0 ml-3 haeusle accent--text">
                    <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
                    <span class="ml-2">{{group.message.linktext}}</span>
                  </h3>
                  <h3 v-else class="mb-0 ml-2 haeusle accent--text d-inline">
                    <v-icon class="accent--text mb-2">mdi-open-in-new</v-icon>
                    <span class="ml-2">{{group.message.link}}</span>
                  </h3>
                </router-link>
              </div>
              <!-- LINK -->
              <v-divider class="my-4"></v-divider>
              <!-- KONTACT -->
              <div v-if="group.message.contact" >
                <h2 class="haeusle ml-2 mb-2" >Kontakt:</h2>
                <span class=" font-weight-bold">{{group.message.contact}}</span>
                <br v-if="group.message.contactmail">
                <v-icon v-if="group.message.contactmail">mdi-email</v-icon>
                <a :href="'mailto:' + group.message.contactmail" v-if="group.message.contactmail" class="black--text ml-2">{{group.message.contactmail}}</a>
                <br v-if="group.message.contactcall">
                <v-icon v-if="group.message.contactcall">mdi-phone</v-icon>
                <span v-if="group.message.contactcall" class="ml-2">{{group.message.contactcall}}</span>
              </div>
              <!-- KONTACT -->
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'groupElement',
    props: {
      group: Object,
      index: Number
    },
    data (){
      return {
        imageStyle: String,
        infoBackStyle: String,
        mounted: Boolean,
        backStyleSize: {
          height: 0,
          width: 0
        }
      }
    },
    mounted: function () {
      // Größe des Hintergrunds der Daten rechts setzen
      this.updateBackStyleSize();
      // Event bei WindowRezie um die Größe wieder anzupassen
      window.addEventListener('resize', this.updateBackStyleSize);

      // Hintergrundfarben anpassen für dieses Element
      var hueNumber =  360 * Math.random();
      if (this.index != undefined){
        hueNumber = this.index * 60;
      }
      this.imageStyle = 'position: absolute; z-index:-1; opacity: 0.7; filter: hue-rotate(' + hueNumber + 'deg) drop-shadow(5px 5px 10px white);';
      this.infoBackStyle = 'position: absolute; z-index:0; opacity: 0.8; filter: hue-rotate(' + hueNumber + 'deg);';
      this.mounted = true;
    },
    methods:{
      updateBackStyleSize: function (){
        if (this.$refs.datacard != undefined){
          this.backStyleSize = {
            height: this.$refs.datacard.clientHeight + 5,
            width: this.$refs.datacard.clientWidth 
          }
        }
      }
    },
    computed:{
      contentStringArray (){
        if (this.group.message.content){
          return this.group.message.content.split("\n");
        }
        return new Array();
      },
      psStringArray (){
        if (this.group.message.ps){
          return this.group.message.ps.split("\n");
        }
        return new Array();
      }
    },
  }
</script>

<style scoped>
  #haeusle {
    font-family: 'Playball', 'sans-serif' !important;
  }
  .noFilter[style] {
    filter: none !important;
  }
  .haeusle {
    font-family: 'Playball';
  }
  .opac{
    opacity: 0.4;
  }
</style>