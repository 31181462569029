<template>
  <div class="transparent">
    <v-container class="transparent" >
      <v-card color="transparent" flat class="pa-2 px-md-12">
        <h2>Datenschutzerklärung</h2>
        <p class="my-4 mb-0">
          <span class="text-h4">Datenschutz auf einen Blick</span>
        </p>
        <br>
        
        <p class="text-h5">1. Allgemeine Hinweise</p>
        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
        <p class="text-h5">Datenerfassung auf unserer Website</p>
        <p class="text-subtitle-1 font-weight-bold mb-0">Rechtsgrundlagen</p>
        
        <p>Die Verarbeitung und Erhebung der Daten erfolgt in Übereinstimmung mit der
        Datenschutzgrundverordnung vom 25.8.2018 (DSG-EKD) und der Einwilligungen
        nach  Art. 6 Abs. 1 lit. a. und Art. 7 DSG-EKD.</p>

        <p class="text-subtitle-1 font-weight-bold mb-0">Rechtsgrundlage ist im Einzelnen</p>
        <v-row class="ml-3 my-0" align-content="center" align="center">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0"> 
              für die Verarbeitung zur Erfüllung vertraglicher Leistungen und Durchführung
              vertraglicher Maßnahmen Art. 6 Abs. 1 lit. b. DSG-EKD,
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            für die Verarbeitung zur Erfüllung rechtlicher Verpflichtungen Art. 6 Abs. 1 lit. c. DSG-EKD,
          </v-col>
        </v-row><v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            für die Verarbeitung zur Wahrung berechtigter Interessen Art. 6 Abs. 1 lit. f. DSG-EKD.
          </v-col>
        </v-row>
        <br>
        <p class="text-subtitle-1 font-weight-bold mb-0">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>

        <br>
        <p class="text-subtitle-1 font-weight-bold mb-0">Wie erfassen wir Ihre Daten?</p>
        <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).</p> 
        <p>Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
        
        <br>
        <p class="text-subtitle-1 font-weight-bold mb-0">Wofür nutzen wir Ihre Daten?</p>
        <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
          
        <br>
        <p class="text-subtitle-1 font-weight-bold mb-0">Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
        <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. 
          Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. 
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. 
          Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
        
        <p class="text-h5">Analyse-Tools und Tools von Drittanbietern</p>
        <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. 
          Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. 
          Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. 
          Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.</p>
        <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.</p>

        <br>
        <p class="text-h5">2. Allgemeine Hinweise und Pflichtinformationen Datenschutz</p>
        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
        <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
        
        <br>
        <p class="text-h5">Hinweis zur verantwortlichen Stelle</p>
        <p class="mt-0 ">Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
        <p class="my-0">Liebenzeller Gemeinschaftsverband e.V.</p>
        <p class="mt-0">Liobastraße 11,</p>
        <p class="mb-0">Telefon: +49 7052 40891-0</p>
        <p class="my-0">
          <span>E-Mail: </span> 
          <a href="mailto:info@lgv.org">info@lgv.org</a>
        </p>
        <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>

        <br>
        <p class="text-h5">Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

        <br>
        <p class="text-h5">Beschwerderecht bei der zuständigen Aufsichtsbehörde</p>
        <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Unsere zuständige Aufsichtsbehörde ist der Datenschutzbeauftragte der EKD</p>
        <p class="mb-0">Hirschstraße 4, 89073 Ulm</p>
        <p class="my-0">Telefon: +49 (0)731 140593-0</p>
        <p class="mt-0">
          <span>E-Mail: </span> 
          <a href="mailto:sued@datenschutz.ekd">sued@datenschutz.ekd</a>
        </p>

        <br>
        <p class="text-h5">Recht auf Datenübertragbarkeit</p>
        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

        <br>
        <p class="text-h5">SSL- bzw. TLS-Verschlüsselung</p>
        <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
        <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

        <br>
        <p class="text-h5">Verschlüsselter Zahlungsverkehr auf dieser Website</p>
        <p>Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt.</p>
        <p>Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss- Symbol in Ihrer Browserzeile.</p>
        <p>Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

        <br>
        <p class="text-h5">Auskunft, Sperrung, Löschung</p>
        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>

        <br>
        <p class="text-h5">Widerspruch gegen Werbe-Mails</p>
        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

        <br>
        <p class="text-h5">3. Datenschutzbeauftragter</p>
        <p class="text-h5">Gesetzlich vorgeschriebener Datenschutzbeauftragter</p>
        <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>
        <p class="mb-0">Peter Pfrommer</p>
        <p class="my-0">Liebenzeller Gemeinschaftsverband e.V.</p>
        <p class="my-0">Liobastraße 11,</p>
        <p class="mt-0">75378 Bad Liebenzell</p>
        <p class="mb-0">Telefon: +49 7052 40891-0</p>
        <p class="mt-0">
          <span>E-Mail: </span> 
          <a href="mailto: datenschutz@lgv.org"> datenschutz@lgv.org</a>
        </p>

        <br>
        <p class="text-h5">4. Datenerfassung auf unserer Website</p>
        <p class="text-h5">Cookies</p>
        <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
        <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
        <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
        <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSG-EKD gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</p>
        
        <br>
        <p class="text-h5">Server-Log-Dateien</p>
        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
        <v-row class="ml-3 my-0" align-content="center" align="center">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0"> 
              Browsertyp und Browserversion
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            verwendetes Betriebssystem
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            Referrer URL
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            Hostname des zugreifenden Rechners
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="my-0 py-0">
            Uhrzeit der Serveranfrage
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col cols="1" sm="auto" class="my-0 py-0">
            •
          </v-col>
          <v-col cols="11" class="mt-0 pt-0">
            IP-Adresse
          </v-col>
        </v-row>

        <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
        <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSG-EKD, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

        <br>
        <p class="text-h5">5. Plugins und Tools</p>
        <p class="text-h5">Google Web Fonts</p>
        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
        <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSG-EKDdar.</p>
        <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
        <p>
          <span>Weitere Informationen zu Google Web Fonts finden Sie unter </span>
          <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>
          <span> und in der Datenschutzerklärung von Google: </span>
          <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
          <span>.</span>
          </p>

        <br>
        <p class="text-h5">6. Partnerprogramme</p>
        <p>Auf unserer Website bieten wir u.a. die Bezahlung via PayPal an. Anbieter dieses Zahlungsdienstes ist die PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22- 24 Boulevard Royal, L-2449 Luxembourg (im Folgenden “PayPal”).</p>
        <p>Wenn Sie die Bezahlung via PayPal auswählen, werden die von Ihnen eingegebenen Zahlungsdaten an PayPal übermittelt.</p>
        <p>Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSG-EKD (Einwilligung) und Art. 6 Abs. 1 lit. b DSG-EKD (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Ein Widerruf wirkt sich auf die Wirksamkeit von in der Vergangenheit liegenden Datenverarbeitungsvorgängen nicht aus.s</p>
        <br>
        <br>
        <br>
        <br>
      </v-card>
    </v-container>   
  </div>
</template>


<script>
// @ is an alias to /src
import don from '../components/Donate.vue';

export default {
  name: 'Home',
  components: {
    don
  },
  mounted (){
    // document.title = "Datenschutzerklärung - Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach";
  }
}
</script>