import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Groups from '../views/Groups.vue'
import History from '../views/History.vue'
import Impress from '../views/Impress.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Wameicamp from '../views/Wameicamp.vue'
import ERlebt from '../views/ERlebt.vue'
import Kalender from '../views/Kalender.vue'
import LandingPage from '../views/LandingPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/home/a'
  },
  {
    path: '/home/:hash',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Liebenzeller Gemeinschaft Haiterbach - das Häusle',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'Liebenzeller Gemeinschaft Haiterbach - das Häusle',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      title: 'Gestern und Heute - Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/groups/:category',
    name: 'Groups',
    component: Groups,
    meta: {
      title: 'Gruppen | Kreise | Gottesdienste - Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/Impress',
    name: 'Impressum',
    component: Impress,
    meta: {
      title: 'Impressum - Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/Datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
    meta: {
      title: 'Datenschutzerklärung - Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen bei der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Aktuelle Veranstaltungen, Gottesdienste, Gruppen, Zeltlager, Impulse'
        }
      ]
    },
  },
  {
    path: '/Waldmeistercamp',
    name: 'Waldmeistercamp',
    component: Wameicamp,
    meta: {
      title: 'Waldmeistercamp - Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        }
      ]
    },
  },
  {
    path: '/Osternerlebt',
    name: 'Osternerlebt',
    component: ERlebt,
    meta: {
      title: 'Ostern ERlebt - Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        },
        {
          property: 'og:description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        }
      ]
    },
  },
  {
    path: '/Kalender',
    name: 'Kalender',
    component: Kalender,
    meta: {
      title: 'Kalender - Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        },  
        {
          property: 'og:description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        }
      ]
    },
  },
  {
    path: '/lp',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      title: 'Liebenzeller Gemeinschaft Haiterbach',
      metaTags: [
        {
          name: 'description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        },  
        {
          property: 'og:description',
          content: 'Herzlich willkommen auf dem Waldmeistercamp der Liebenzeller Gemeinschaft Haiterbach - christliche Gemeinde in Haiterbach - Zeltlager'
        }
      ]
    },
  }
]

const router = new VueRouter({
	// mode: 'history',
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router
