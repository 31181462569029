<template>
  <div class="white" >
    <v-container class="grey lighten-3">
      <br>
      <v-row vertical-align="center">
        <v-col cols="auto">
          <h2 class="d-inline">Du suchst eine bestimmte Veranstaltung? Hier findest Du die Termine im </h2>
          <h1 class="my-1 haeusle blue--text text--darken-4 d-inline"> Häusle </h1>
          <h2 class="d-inline">schön übersichtlich in einem Kalender.</h2>
        </v-col>
        <v-col cols="auto">
          <h1 class="">Kalender</h1>
        </v-col>
        <!-- <v-col cols="auto">
          <h1 class="my-1 haeusle blue--text text--darken-4">27. März - 7. April 2023</h1>
        </v-col> -->
      </v-row>
      <br>
      <v-row>
        <!-- <v-col cols="auto">
          <h3> Übersicht über die bereits gebuchten Termine</h3>
        </v-col> -->
        <!-- <v-col cols="auto">
          <h3> 
            <span>Neue Termine bitte bei Michael Bauer Buchen: </span>
            <a href="mailto:Michael.bauer@lgv.org">@Michael Bauer</a> 
          </h3>
        </v-col> -->
      </v-row>

      <v-card >
        <iframe width="100%" height="800px" style="border-width:0px" src="https://haiterbach.church.tools/?q=churchcal&category_id=2,3,5,1&category_select=2,3,5,1&embedded=true&viewname=calView#CalView/"></iframe>
      </v-card >
    </v-container>
  </div>
</template>

<script>

export default {
  name: "Camp",
  components:{
    // Carousel
  },
  data (){
    return {
      showW: false,
      showM: false,
      showC: false,
    }
  },
  mounted (){
    // document.title = "LGV Haiterbach - Waldmeistercamp";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
    var that = this;
    setTimeout(function() {
        that.showW = true;
      }, 10);
      
    setTimeout(function() {
        that.showM = true;
      }, 200);
      
    setTimeout(function() {
        that.showC = true;
      }, 400);
  
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/php/ServerOperations.php', true);
    xhr.send("getcamp");
  },
  methods:{
  },
  computed:{
    istOutDated(){
      return Date.now() > new Date(2022, 6, 7, 0, 0, 0);
    }
  }
}
</script>

<style>
.leftStart{
  left: -100%;
  transition: left 0.5s;
}
.rightStart{
  left: 100%;
  transition: left 0.5s;
}

.slide{
  left: 0%
}
</style>