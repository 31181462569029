<template>
  <v-app >
    <div class="grey lighten-3">
      <Navigation :navigationitems="navitems"/>
          
      <v-main class="transparent">
        <router-view class="transparent"></router-view>
        <br>
        <br>
        <br>
        <br>
        <br>
      </v-main>

      <v-footer absolute max-height="150" color="blue-grey lighten-3 pa-0">
        <v-container fluid class="pa-0 ma-0 blue-grey lighten-3">
          <v-row class="pa-0 ma-0">
            <v-col cols="3" lg="4">
              <v-layout align-end justify-end>
                <a target="_blank" href="https://www.liebenzell.org/" class="">
                  <v-img max-width="30" src="../src/images/LM_logo.png" ></v-img>
                </a>
              </v-layout>
            </v-col>
            <v-col cols="2" lg="1">
              <v-layout justify-center >
                <v-btn depressed fab small class="pa-0 ma-0 transparent" link href="https://www.youtube.com/channel/UCuOp8nzRF2kvyMgkfxeudnQ" target="_blank">
                  <v-icon color="blue darken-4">mdi-youtube</v-icon>
                </v-btn>
              </v-layout>
            </v-col>
            <v-col cols="2">
              <v-layout justify-center >
                <v-btn depressed fab small class="pa-0 ma-0 transparent" link href="https://www.instagram.com/liebenzellergemeinschaft_htb/" target="_blank">
                  <v-icon color="blue darken-4">mdi-instagram</v-icon>
                </v-btn>
              </v-layout>
            </v-col>  
            <v-col cols="2" lg="1">
              <v-layout justify-center >
                <v-btn depressed fab small class="pa-0 ma-0 transparent" link href="mailto:info@haeusle.org">
                  <v-icon  color="blue darken-4">mdi-email</v-icon>
                </v-btn>
              </v-layout>
            </v-col>
          <v-col cols="3" lg="4">            
              <v-layout align-end justify-start>
                <a target="_blank" href="https://www.swdec.de/">
                  <v-img max-width="30" src="../src/images/EC_Logo.png"></v-img>
                </a>
              </v-layout>
          </v-col>
          </v-row>
          <p class="text-center">&copy; {{year}} Liebenzeller Gemeinschaft Haiterbach, Brunnenstraße 13, 72221 Haiterbach</p>
          <v-row justify="center" >
            <v-col cols="auto">
              <span>
                <router-link class="text-decoration-none blue--text font-weight-bold text--darken-4" to="/Impress">Impressum</router-link> 
              </span>
            </v-col >
            <v-col cols="auto">
              <span >
                <router-link class="text-decoration-none blue--text font-weight-bold text--darken-4" to="/Datenschutz">Datenschutzerklärung</router-link> 
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
  import Navigation from '@/components/Navigation'
  
  export default {
    name: 'App',
    components: {
      Navigation
    },

    data: () => ({
      title: "Liebenzeller Gemeinschaft Hatierbach - das Häusle",
      navitems: [
        {icon: "mdi-home", text: "Aktuelles", link: "/home/impuls"},
        // {icon: "mdi-calendar", text: "Aktuelles", link: "/aktuelles"},
        {icon: "groups", text: "Gruppen", link: "/groups", 
          subitems: [
            {text: "Jugend", link: { path: '/groups', hash: 'youth' }},
            {text: "Erwachsene", link: { path: '/groups', hash: 'adult' }},
            ]},
        {icon: "import_contacts", text: "Gestern und Heute", link: "/history"},
        {icon: "mdi-tent", text: "Waldmeistercamp", link: "/Waldmeistercamp"},
        // {icon: "mdi-cross-outline", text: "Ostern: ERlebt", link: "/Osternerlebt"},
        {icon: "mdi-calendar-blank-multiple", text: "Kalender", link: "/Kalender"},
      ]
    }),
    computed: {
      year: function() {
        return new Date().getFullYear();
      }
    }
  };
</script>

<style>
  b-wh{
    background-color: gray;
  }
  .home{
    height: 400;
  }
</style>
