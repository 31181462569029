<template>
  <div class="transparent">
    <v-container class="transparent">
      <h2>Impressum</h2>
      <br>
      <br>
      <p class="my-4 mb-0">
        <span class="text-h6">Wir sind per Kooperationsvertrag Teil des Liebenzeller Gemeinschaftsverbands (LGV): </span>
      </p>
      
      <p class="my-0">Liobastraße 11</p>
      <p class="mt-0">75378 Bad Liebenzell</p>
      <p class="mb-0">telefon: +49 7052 408910</p>
      <p class="my-0">
        <span>e-Mail: </span>
        <a class="text-decoration-none blue--text text--darken-4" href="mailto:info@lgv.org">info@lgv.org</a>
      </p>
      <p>Internet: <a class="text-decoration-none blue--text text--darken-4 font-italic" target="_blank" href="https://www.lgv.org">www.lgv.org/</a></p>

      <h3>Vertretungsberechtigter Vorstand</h3>
      <p class="my-0 text-body-2">(Anschrift wie oben) </p>
      <p class="my-0">Martin Siehler (Vorsitzender)</p>
      <p class="my-0">Lydia Scheuvens</p>
      <p class="my-0">Thomas Ritter</p>
      <p class="my-0">Michael Piertzik</p>
      <br>
      <p class="">Finanzamt Calw UST-Ident: DE153476691</p>

      <br>
      <br>


      <p class="text-h6 mb-0">Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach</p>
      <span>Brunnenstraße 13</span>
      <p>72221 Haiterbach</p>
      <p class="my-0">
        <span>e-Mail: </span>
        <a class="text-decoration-none blue--text text--darken-4" href="mailto:info@haeusle.org">info@haeusle.org</a>
      </p>
      <p class="mb=2">
        <span>internet: </span>
        <a class="text-decoration-none blue--text text--darken-4" href="http://www.haeusle.org">www.haeusle.org</a>
      </p>
      
      <h3>Vorstand</h3>
      <p class="my-0">Michael Helber</p>
      <p class="my-0">Hohenrainstraße 29</p>
      <p class="mt-0">72221 Haiterbach</p>
      <p class="mb-0">Eintragung im Vereinsregister:</p>
      <p class="mb-0">Amtsgericht: Stuttgart</p>
      <p class="">Registernummer: 340038 </p>
      <br>
      <don></don>
      <br>
      <br>
      <p>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt verlinkter Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
      <span>
         <span>
           Plattform der EU-Kommission zur Online-Streitbeilegung: 
          </span>
          <a class="text-decoration-none blue--text text--darken-4 font-italic" target="_blank" href="https://www.ec.europa.eu/consumers/odr">www.ec.europa.eu/consumers/odr</a>
      </span>
      <br>
      <br>
      <br>
    </v-container>   
  </div>
</template>


<script>
// @ is an alias to /src
import don from '../components/Donate.vue';

export default {
  name: 'Home',
  components: {
    don
  },
  mounted () {
    // document.title = "Impressum - Landeskirchliche Liebenzeller Gemeinschaft e.V. Haiterbach";
  }
}
</script>