<template>
  <div class="about ">
    <v-container class="ma-3">
      <h1>This is an about page</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, ea dolorem, harum necessitatibus placeat repudiandae laboriosam consequatur eos minima enim eius animi adipisci aliquid perspiciatis assumenda, labore soluta fuga exercitationem.</p>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "about",
  mounted() {
    // document.title = "LGV Haiterbach - das Häusle";
  },
}
</script>

